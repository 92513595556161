<template>
  <CFooter>
    <div class="text-muted text-center">
      <small>
        Copyright &copy; 2024
        <a href="https://nextdigital.co.id">nextdigital</a> All rights reserved.
      </small>
    </div>
    <!-- <div>
      Copyright
      <span class="ms-1">
        &copy; {{ new Date().getFullYear() }} Socom CMS All rights reserved.
      </span>
    </div> -->
  </CFooter>
</template>

<script>
export default {
  name: 'AppFooter',
}
</script>
