export default [
  {
    component: 'CNavItem',
    name: 'Home',
    to: '/home',
    icon: 'cil-home',
  },
  {
    component: 'CNavItem',
    name: 'Pipeline',
    to: '/pipeline',
    icon: 'cil-line-style',
    badge: {
      color: 'info',
      text: 'V2',
    },
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Dashboard',
  //   to: '/dashboard',
  //   icon: 'cil-speedometer',
  //   badge: {
  //     color: 'info',
  //     text: 'V1',
  //   },
  // },
  {
    component: 'CNavItem',
    name: 'Monitoring',
    to: '/monitoring',
    icon: 'cil-screen-desktop',
  },
  {
    component: 'CNavItem',
    name: 'Customers',
    to: '/customers',
    icon: 'cil-people',
  },
  {
    component: 'CNavItem',
    name: 'Export',
    to: '/export',
    icon: 'cil-share-boxed',
  },
  {
    component: 'CNavTitle',
    name: 'Config',
  },
  // {
  //   component: 'CNavItem',
  //   name: 'Datasheet',
  //   to: '/datasheet',
  //   icon: 'cil-puzzle',
  // },
  {
    component: 'CNavItem',
    name: 'Messaging',
    to: '/messaging',
    icon: 'cil-puzzle',
  },
  {
    component: 'CNavTitle',
    name: 'Account',
  },
  {
    component: 'CNavItem',
    name: 'Profile',
    to: '/profile',
    icon: 'cil-user',
  },
]
