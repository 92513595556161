<template>
  <router-view />
</template>

<style lang="scss">
// Import Main styles for this application
@import 'styles/style';
// @import 'datatables.net-dt';
@import 'datatables.net-bs5';

@import 'vue3-funnel-graph-js/dist/style.css';
</style>
