import axios from 'axios'
import TokenGenerator from '../services/utility'

const tokenGenerator = new TokenGenerator()
// const tokenUser = localStorage.getItem('user')
// const user = tokenUser ? JSON.parse(tokenGenerator.decrypt(tokenUser)) : null
function dataURItoBlob(dataURI) {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: mimeString })
}

class UserServices {
  // GET BrandCONFIG
  async getBrandConfig() {
    let userData = null
    while (!userData) {
      const tokenUsr = localStorage.getItem('global')
      userData = tokenUsr ? tokenGenerator.decrypt(tokenUsr) : null
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    const sequence = 'NA'
    const { requestId, token, baseURL } = tokenGenerator.generateToken(sequence)
    const config = {
      headers: {
        RequestId: requestId,
        token: token,
      },
    }
    return axios.get(baseURL + 'socom/brand/config/get/' + userData, config)
  }

  getBrandConfigIfUserExists(data) {
    return new Promise((resolve, reject) => {
      if (!data) {
        reject('User data not available')
      } else {
        const sequence = 'NA'
        const { requestId, token, baseURL } =
          tokenGenerator.generateToken(sequence)
        const config = {
          headers: {
            RequestId: requestId,
            token: token,
          },
        }
        axios
          .get(baseURL + 'socom/brand/config/get/' + data.brand_id, config)
          .then((response) => {
            resolve(response)
          })
          .catch((error) => {
            reject(error)
          })
      }
    })
  }

  async updateLogo(imagePreviews) {
    let userData = null
    while (!userData) {
      const tokenUsr = localStorage.getItem('global')
      userData = tokenUsr ? tokenGenerator.decrypt(tokenUsr) : null
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    let formData = new FormData()
    for (let i = 0; i < imagePreviews.length; i++) {
      const imageData = dataURItoBlob(imagePreviews[i].src)
      const imageName = imagePreviews[i].name
      formData.append('brand_logo', imageData, imageName)
    }

    let sequence = 'NA'
    const { requestId, token, baseURL } = tokenGenerator.generateToken(sequence)
    const configPost = {
      // onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      headers: {
        'Content-Type': 'multipart/form-data',
        RequestId: requestId,
        token: token,
      },
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ', ' + pair[1])
    // }
    return axios.post(
      baseURL + 'socom/brand/' + userData + '/profile/update',
      formData,
      configPost,
    )
  }
  async updateProfile(data) {
    let userData = null
    while (!userData) {
      const tokenUsr = localStorage.getItem('global')
      userData = tokenUsr ? tokenGenerator.decrypt(tokenUsr) : null
      await new Promise((resolve) => setTimeout(resolve, 100))
    }
    const body = data
    const keys = Object.keys(body).sort()

    let sequence = ''

    keys.forEach((key) => {
      if (typeof body[key] === 'object') {
        body[key].forEach((item) => {
          sequence += JSON.stringify(item)
        })
      } else {
        sequence += body[key]
      }
    })
    const { requestId, token, baseURL } = tokenGenerator.generateToken(sequence)
    const config = {
      headers: {
        RequestId: requestId,
        token: token,
      },
    }
    return axios.post(
      baseURL + '/socom/brand/' + userData + '/config/update',
      body,
      config,
    )
  }
}
export default new UserServices()
