import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsExample from '@/components/DocsExample'
import moment from 'moment'
import $ from 'jquery'
import VueDatePicker from '@vuepic/vue-datepicker'
import VueTelInput from 'vue-tel-input'

import 'vue-tel-input/vue-tel-input.css'
import '@vuepic/vue-datepicker/dist/main.css'

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { VDataTable } from 'vuetify/labs/VDataTable'
import { fa } from 'vuetify/iconsets/fa'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import '@fortawesome/fontawesome-free/css/all.css'

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
      fa,
    },
  },
  components,
  directives,
  VDataTable,
})

const app = createApp(App)
app.use(store)
app.use(router)
app.use(CoreuiVue)
app.use(VueTelInput)
app.use(vuetify)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsExample', DocsExample)
app.component('VueDatePicker', VueDatePicker)
app.config.globalProperties.moment = moment

window.$ = $

app.mount('#app')
