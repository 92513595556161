const state = {
  search: {
    leads: {
      query: '',
      field: '',
    },
    appointments: {
      query: '',
      field: '',
    },
    quotations: {
      query: '',
      field: '',
    },
    invoices: {
      query: '',
      field: '',
    },
    receipts: {
      query: '',
      field: '',
    },
    installations: {
      query: '',
      field: '',
    },
  },
  position: '',
}

const mutations = {
  setPosition(state, position) {
    state.position = position
  },
  setSearchQuery(state, { module, query, field }) {
    state.search[module].query = query
    state.search[module].field = field
  },
  RESET_SEARCH_STATE(state) {
    // Reset the state to its initial values
    for (const module in state.search) {
      state.search[module].query = ''
      state.search[module].field = ''
    }
    state.position = ''
  },
}

const actions = {
  setPosition({ commit }, value) {
    commit('setPosition', value)
  },
  updateSearchQuery({ commit }, { module, query, field }) {
    commit('setSearchQuery', { module, query, field })
  },
  resetSearchState({ commit }) {
    // Reset the state to its initial values
    commit('RESET_SEARCH_STATE')
  },
}

const getters = {
  getPosition: (state) => state.position,
  getSearchQuery: (state) => (module) => ({
    query: state.search[module].query,
    field: state.search[module].field,
  }),
}

export const searching = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
