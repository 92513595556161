<template>
  <CHeader position="sticky" class="mb-4">
    <CContainer fluid>
      <CHeaderToggler class="ps-1" @click="$store.commit('toggleSidebar')">
        <CIcon icon="cil-menu" size="lg" />
      </CHeaderToggler>
      <CHeaderBrand
        class="mx-auto text-uppercase"
        :href="
          '//' +
          (userData && userData.domain ? userData.domain : 'nextdigital.co.id')
        "
        target="_blank"
      >
        <!-- <img :src="userData.brand_logo" alt="" height="32" class="me-1" /> -->
        <strong>
          {{ userData && userData.brand_name ? userData.brand_name : '' }}
        </strong>
        <!-- </a> -->
      </CHeaderBrand>
      <CHeaderNav>
        <AppHeaderDropdownAccnt />
      </CHeaderNav>
    </CContainer>
    <CHeaderDivider />
    <CContainer fluid>
      <AppBreadcrumb />
    </CContainer>
  </CHeader>
</template>

<script>
import AppBreadcrumb from './AppBreadcrumb'
import AppHeaderDropdownAccnt from './AppHeaderDropdownAccnt'
import UserServices from '@/services/user.service'
export default {
  name: 'AppHeader',
  components: {
    AppBreadcrumb,
    AppHeaderDropdownAccnt,
  },
  data() {
    return {
      brandData: [],
    }
  },
  computed: {
    userData() {
      return this.$store.state.auth.user
    },
  },
  mounted() {
    this.getBrandData()
  },
  methods: {
    getBrandData() {
      const intervalId = setInterval(async () => {
        if (this.userData) {
          try {
            const brandId = this.userData
            const response =
              await UserServices.getBrandConfigIfUserExists(brandId)
            clearInterval(intervalId)
            if (response.data.message_action.includes('SUCCESS')) {
              const responseData = response.data.message_data
              this.$store.dispatch('auth/updateUser', responseData)
            }
          } catch (error) {
            console.error('Error fetching brand config:', error)
          }
        }
      }, 500)
    },
  },
}
</script>
