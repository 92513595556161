<template>
  <CDropdown variant="nav-item" @click.prevent="handleClick">
    <CDropdownToggle placement="bottom-end" class="py-0" :caret="false">
      <!-- <CAvatar :src="avatar" size="md" /> -->
      <v-icon color="dark" icon=" mdi-account-circle" size="x-large" />
    </CDropdownToggle>
    <CDropdownMenu class="p-0">
      <CDropdownHeader component="h6" class="bg-light fw-semibold py-2">
        Account
      </CDropdownHeader>
      <CDropdownItem @click="logOut">
        <CIcon icon="cil-lock-locked" />
        Logout
      </CDropdownItem>
    </CDropdownMenu>
  </CDropdown>
</template>

<script>
import avatar from '@/assets/images/avatars/8.jpg'
export default {
  name: 'AppHeaderDropdownAccnt',
  setup() {
    return {
      avatar: avatar,
      itemsCount: 42,
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
    },
    handleClick() {
      // Your click event handler logic goes here
      console.log('Link clicked')
    },
  },
}
</script>
