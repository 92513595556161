import axios from 'axios'
import TokenGenerator from '../services/utility'

const tokenGenerator = new TokenGenerator()
const tokenUser = localStorage.getItem('user')
const user = tokenUser ? JSON.parse(tokenGenerator.decrypt(tokenUser)) : null
// let user

// if (JSON.parse(tokenUser) && typeof JSON.parse(tokenUser) === 'object') {
//   window.localStorage.clear()
// } else {
//   user = tokenUser ? JSON.parse(tokenGenerator.decrypt(tokenUser)) : null
// }

const initialState = user
  ? { status: { loggedIn: true }, user, msg: '', loading: false, error: false }
  : {
      status: { loggedIn: false },
      user: null,
      msg: '',
      loading: false,
      error: false,
    }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, user) {
      commit('loginRequest', true)
      // const tokenGenerator = new TokenGenerator()/
      const sequence = user.password + user.username
      const { requestId, token, baseURL } =
        tokenGenerator.generateToken(sequence)
      const config = {
        headers: {
          RequestId: requestId,
          token: token,
        },
      }
      try {
        const resp = await axios.post(
          baseURL + 'v1/auth/login',
          {
            username: user.username,
            password: user.password,
          },
          config,
        )

        const { message_action, message_data, message_desc } = resp.data

        if (message_action.includes('SUCCESS')) {
          const data = JSON.stringify(message_data)
          const encryptedToken = tokenGenerator.encrypt(data)
          const encryptedGlobal = tokenGenerator.encrypt(message_data.brand_id)
          localStorage.setItem('user', encryptedToken)
          localStorage.setItem('global', encryptedGlobal)
          commit('loginSuccess', message_data)
        } else {
          commit('loginFailure', message_desc)
          localStorage.removeItem('user')
          return Promise.reject(message_desc)
        }
      } catch (err) {
        commit('loginFailure', 'Login failed. No user data received.')
        localStorage.removeItem('user')
        return Promise.reject(err)
      } finally {
        commit('loginRequest', false)
      }
    },
    logout({ commit }) {
      // localStorage.removeItem('user')
      window.localStorage.clear()
      commit('logout')
    },
    updateUser({ commit }, newUser) {
      commit('updateUser', newUser)
    },
  },
  mutations: {
    loginRequest(state, newLloading) {
      state.loading = newLloading
    },
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state, msg) {
      state.status.loggedIn = false
      state.error = true
      state.user = null
      state.msg = msg
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
      location.reload()
    },
    updateUser(state, newUser) {
      state.user = newUser
    },
  },
}
