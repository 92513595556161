import { createStore } from 'vuex'
import { auth } from './auth.module'
import { dash } from './dash.module'
import { dashV2 } from './dashv2.module'
import { cust } from './cust.module'
import { due } from './due.module'
import { sorting } from './Funnel/sorting.module'
import { searching } from './Funnel/searching.module'

export default createStore({
  state: {
    sidebarVisible: false,
    sidebarUnfoldable: false,
  },
  mutations: {
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },
  },
  actions: {},
  modules: {
    auth,
    dashboard: dash,
    dashboardV2: dashV2,
    customer: cust,
    overdue: due,
    sorting: sorting,
    searching: searching,
  },
})
