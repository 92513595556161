const state = {
  sortBy: {
    leads: 'date_timestamp',
    appointments: 'date_timestamp',
    quotations: 'date_timestamp',
    invoices: 'date_timestamp',
    receipts: 'date_timestamp',
    installations: 'date_timestamp',
  },
  sortDesc: {
    leads: 'desc',
    appointments: 'desc',
    quotations: 'desc',
    invoices: 'desc',
    receipts: 'desc',
    installations: 'desc',
  },
}

const mutations = {
  setSortBy(state, { module, value }) {
    state.sortBy[module] = value
  },
  setSortDesc(state, { module, value }) {
    state.sortDesc[module] = value
  },
  RESET_SORTING_STATE(state) {
    // Reset the state to its initial values
    state.sortBy = {
      leads: 'date_timestamp',
      appointments: 'date_timestamp',
      quotations: 'date_timestamp',
      invoices: 'date_timestamp',
      receipts: 'date_timestamp',
      installations: 'date_timestamp',
    }
    state.sortDesc = {
      leads: 'desc',
      appointments: 'desc',
      quotations: 'desc',
      invoices: 'desc',
      receipts: 'desc',
      installations: 'desc',
    }
  },
}

const actions = {
  updateSortBy({ commit }, { module, value }) {
    commit('setSortBy', { module, value })
  },
  updateSortDesc({ commit }, { module, value }) {
    commit('setSortDesc', { module, value })
  },
  resetSortingState({ commit }) {
    // Reset the state to its initial values
    commit('RESET_SORTING_STATE')
  },
}

const getters = {
  getSortBy: (state) => (module) => state.sortBy[module],
  getSortDesc: (state) => (module) => state.sortDesc[module],
}

export const sorting = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
