const state = {
  page: 1,
  itemsPerPage: 10,
  search: '',
  search_by: '',
  selectedRowIndex: null,
  sortBy: 'date_timestamp',
  sortDesc: 'desc',
}

const mutations = {
  setPage(state, page) {
    state.page = page
  },
  setItemsPerPage(state, itemsPerPage) {
    state.itemsPerPage = itemsPerPage
  },
  setSearch(state, search) {
    state.search = search
  },
  setSearchBy(state, searchBy) {
    state.search_by = searchBy
  },
  setSelectedRowIndex(state, selectedRowIndex) {
    state.selectedRowIndex = selectedRowIndex
  },
  setSortBy(state, sortBy) {
    state.sortBy = sortBy
  },
  setSortDesc(state, sortDesc) {
    state.sortDesc = sortDesc
  },
}

const actions = {
  setSearchBy({ commit }, value) {
    commit('setSearchBy', value)
  },
  setSearch({ commit }, search) {
    commit('setSearch', search)
  },
  setSortBy({ commit }, sortBy) {
    commit('setSortBy', sortBy)
  },
  setSortDesc({ commit }, sortDesc) {
    commit('setSortDesc', sortDesc)
  },
}

const getters = {
  // Define your getters here
  getPage: (state) => state.page,
  getItemsPerPage: (state) => state.itemsPerPage,
  getSearch: (state) => state.search,
  getSearchBy: (state) => state.search_by,
  getSelectedRowIndex: (state) => state.selectedRowIndex,
  getSortBy: (state) => state.sortBy,
  getSortDesc: (state) => state.sortDesc,
}

export const dash = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
