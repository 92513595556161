import { createRouter, createWebHashHistory } from 'vue-router'
import DefaultLayout from '@/layouts/DefaultLayout'
// import nav_v1 from '@/_nav_v1.js'
// import nav_v2 from '@/_nav_v2.js'
// import TokenGenerator from '../services/utility'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: DefaultLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: '',
        component: () => import('@/views/home/Home.vue'),
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@/views/dashboard/DashboardV2.vue'),
      },
      {
        path: '/profile',
        name: 'Profile',
        component: () => import('@/views/profile/Profile.vue'),
      },
      {
        path: '/customers',
        name: 'Customers',
        redirect: '/customers/list',
        children: [
          {
            path: 'list',
            name: '',
            component: () => import('@/views/customer/Customer.vue'),
          },
          {
            path: 'manage/:id/:phone',
            name: 'Manage',
            component: () => import('@/views/funnel/SalesFunnel.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/export',
        name: 'Export',
        component: () => import('@/views/export/Export.vue'),
      },
      {
        path: '/datasheet',
        name: 'Datasheet',
        component: () => import('@/views/config/Datasheet.vue'),
      },
      {
        path: '/messaging',
        name: 'Messaging',
        component: () => import('@/views/config/Messaging.vue'),
      },
      {
        path: '/pipeline',
        name: 'Pipline',
        component: () => import('@/views/dahsboard_v2/Dashboard_V2.vue'),
      },
      {
        path: '/monitoring',
        name: 'Monitoring',
        component: () => import('@/views/monitoring/MonitoringView.vue'),
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/pages/Login'),
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/pages/PageSession.vue'),
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

// const tokenGenerator = new TokenGenerator()

// router.beforeEach((to, from, next) => {
//   const loggedIn = localStorage.getItem('user')

//   if (!loggedIn) {
//     // Not logged in, restrict access to protected routes
//     if (to.path !== '/login') {
//       next('/login') // Redirect to login page if trying to access a restricted page
//     } else {
//       next() // Allow navigation to public pages
//     }
//     return
//   }

//   try {
//     const user = JSON.parse(tokenGenerator.decrypt(loggedIn))
//     const version = user.version || 'v1'
//     const navVersion = version === 'v1' ? nav_v1 : nav_v2
//     const selectedNav = navVersion || []

//     // Map allowed routes from selectedNav
//     const allowedRoutes = selectedNav.map((item) => item.to)

//     if (allowedRoutes.includes(to.path)) {
//       next() // Allow navigation to the destination route
//     }
//   } catch (error) {
//     console.error('Error parsing user data:', error)
//     next('/login') // Redirect to login page if user data cannot be parsed
//   }
// })
router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
